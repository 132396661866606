<template>
  <div class="login-page">
    <login-form>
      <img
        :src="require('@images/logo_black.png')"
        class="logo">
      <div class="login-header">
        {{ $t('login.header') }}
      </div>
    </login-form>
  </div>
</template>

<script>
const LoginForm = () => import('@components/LoginForm')
export default {
  name: 'login-page',
  components: {
    LoginForm
  }
}
</script>

<style lang="scss" scoped>
.login-page {
  min-height: 100vh;

  .login-form {
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 0 $space-m;
    transform: translate(-50%, -50%);

    > .logo {
      display: block;
      width: 65px;
      margin: 0 auto;
    }

    .login-header {
      margin-top: $space-l;
      margin-bottom: $space-xl;
      text-align: center;
      @extend %display_medium;
    }
  }
}
</style>
